<template>
  <div
    class="tw-flex shipment-item-content tw-py-2 tw-relative tw-px-4 lg:tw-px-8"
    :class="{
      'has-price': hasPrice
    }"
  >
    <shipment-item-address-dates
      :shipment="shipment"
      class="tw-flex-1 tw-truncate"
    />

    <div
      v-if="hasSpacer"
      class="shipment-item-content__spacer"
      data-test="spacer"
    />

    <shipment-item-options
      v-if="hasOptions"
      :shipment="shipment"
      class="pr-3 pl-4 tw-justify-end"
    />

    <shipment-item-tracking
      v-if="hasTracking"
      :shipment="shipment"
      class="tw-flex-1"
    />

    <div
      v-if="hasPrice"
      class="shipment-item-content__price tw-z-10 tw-flex tw-justify-end tw-items-center"
    >
      <shipment-item-price
        :shipment="shipment"
      />
    </div>

    <shipment-item-rate
      v-if="hasRating"
      :shipment="shipment"
    />

    <shipment-item-cancellation-dates
      v-if="hasCancellationDates"
      :shipment="shipment"
    />
  </div>
</template>

<script>
  import ShipmentItemAddressDates from './_subs/ShipmentItemAddressDates/index.vue'
  import ShipmentItemPrice from './_subs/ShipmentItemPrice/index.vue'
  import ShipmentItemOptions from './_subs/ShipmentItemOptions/index.vue'
  import ShipmentItemTracking from './_subs/ShipmentItemTracking/index.vue'
  import ShipmentItemCancellationDates from './_subs/ShipmentItemCancellationDates/index.vue'
  import ShipmentItemRate from './_subs/ShipmentItemRate/index.vue'

  /**
   * @module component - shipmentItemContent
   * @param {boolean} [hasOptions=true]
   * @param {boolean} [hasPrice=true]
   * @param {boolean} [hasTracking=false]
   * @param {boolean} [hasCancellationDates=false]
   * @param {object} shipment
   */
  export default {
    name: 'ShipmentItemContent',
    components: {
      ShipmentItemAddressDates,
      ShipmentItemOptions,
      ShipmentItemPrice,
      ShipmentItemTracking,
      ShipmentItemCancellationDates,
      ShipmentItemRate
    },
    props: {
      shipment: {
        type: Object,
        required: true
      },
      hasOptions: {
        type: Boolean,
        default: true
      },
      hasPrice: {
        type: Boolean,
        default: true
      },
      hasTracking: {
        type: Boolean,
        default: false
      },
      hasCancellationDates: {
        type: Boolean,
        default: false
      },
      hasRating: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      /**
       * Returns true if we should include a white space in the layout
       * for certains routes.
       * @function hasSpacer
       * @returns {boolean}
       */
      hasSpacer () {
        return ['planned', 'awaiting_acceptation'].includes(this.$route.params.state)
      }
    }
  }
</script>

<style lang="scss" scoped>

  .shipment-item-content {
    &__spacer {
      flex: 0.25;
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      &.has-price {
        padding-bottom: 24px !important;
      }

      &__spacer {
        display: none;
      }
    }

    .shipment-item-address-dates {
      flex: 0.5;

      @media only screen and (max-width: $breakpoint-tablet) {
        flex: 1;
      }
    }

    .shipment-item-carrier,
    .shipment-item-rate,
    .shipment-item-cancellation-dates,
    .shipment-item-options,
    .shipment-item-tracking,
    &__price {
      flex: 0.25;
    }

    .shipment-item-options {
      @media only screen and (max-width: $breakpoint-laptop-s) {
        display: none;
      }
    }

    .shipment-item-tracking {
      @media only screen and (max-width: $breakpoint-tablet) {
        display: none;
      }
    }

    .shipment-item-content__price {
      @media only screen and (max-width: $breakpoint-laptop-s) {
        flex: 0.5;
      }

      @media only screen and (max-width: $breakpoint-tablet) {
        position: absolute;
        right: 16px;
        bottom: -12px;
      }

      @media only screen and (max-width: $breakpoint-mobile-l) {
        right: 16px;
      }
    }

    .shipment-item-carrier,
    .shipment-item-rate,
    .shipment-item-cancellation-dates {
      @media only screen and (max-width: $breakpoint-tablet) {
        display: none;
      }
    }
  }

</style>
