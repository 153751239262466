<template>
  <div class="shipment-item-address-dates">
    <shipment-item-direction
      :shipment="shipment"
      direction="pickup"
      class="tw-flex-1 has-link pr-4"
      data-test="pickup"
    />
    <shipment-item-direction
      :shipment="shipment"
      direction="delivery"
      class="tw-flex-1 pr-4"
      data-test="delivery"
    />
  </div>
</template>

<script>
  import ShipmentItemDirection from './_subs/ShipmentItemDirection/index.vue'

  /**
   * @module component - shipmentItemAddressDates
   * @param {object} shipment
   */
  export default {
    name: 'ShipmentItemAddressDates',
    components: {
      ShipmentItemDirection
    },
    props: {
      shipment: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>

  .shipment-item-address-dates {
    position: relative;

    &::after {
      position: absolute;
      content: '';
      width: 7px;
      height: 20px;
      top: 10px;
      right: 8px;
      border: 1px solid $third-text;
      border-left: none;
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      width: 100%;
    }
  }

</style>
