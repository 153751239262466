var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{attrs:{"to":"dialogs"}},[_c('transition',{attrs:{"name":_vm.transitionName}},[(_vm.modalState)?_c('div',{staticClass:"ctk-dialog tw-fixed tw-left-0 tw-top-0 tw-right-0 tw-bottom-0 tw-flex tw-w-full tw-h-full tw-overflow-y-auto",class:_vm.$attrs['modal-class']},[_c('div',{staticClass:"modal-wrapper tw-flex tw-flex-col tw-w-full tw-p-4 tw-justify-center tw-items-center tw-max-h-full tw-align-middle tw-min-h-full"},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.clickOutside),expression:"clickOutside"}],staticClass:"modal-container tw-bg-bg-primary tw-mx-auto tw-my-0 tw-shadow-xl tw-max-h-full tw-w-full tw-transform tw-overflow-y-auto",class:{ 'modal-container--has-bottom-ribbon': _vm.bottomRibbon },style:({
            maxWidth: _vm.maxWidth
          })},[(!_vm.hideHeader)?_c('div',{staticClass:"ctk-dialog__header modal-header tw-flex tw-items-start tw-justify-between tw-p-4 tw-flex tw-justify-between tw-items-center tw-border-none",class:{
              'tw-bg-secondary': !_vm.transparentHeader,
              'modal-header--transparent': _vm.transparentHeader,
            }},[_vm._t("modal-header",function(){return [_c('h5',{staticClass:"tw-font-normal tw-m-0 tw-w-full tw-text-white tw-text-xl"},[_vm._t("title")],2),_c('transition',{attrs:{"name":"fade"}},[(_vm.hasClose)?_c('ui-button',{staticClass:"ctk-dialog__header__close tw-flex-shrink-0 close-modal !tw-bg-transparent",attrs:{"title":_vm._f("capitalize")(_vm.$t('close')),"variant":_vm.transparentHeader ? 'light' : 'primary'},on:{"click":_vm.close},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('ui-ctk-icon',{attrs:{"name":"close"}})]},proxy:true}],null,false,2375600803)}):_vm._e()],1)]})],2):_vm._e(),_c('div',{staticClass:"modal-body p-0",style:({
              backgroundColor: _vm.bgColor
            })},[_vm._t("default")],2),(!_vm.hideFooter)?_c('div',{staticClass:"modal-footer"},[_vm._t("footer",function(){return [_c('ui-button',{attrs:{"type":"button","variant":"primary"},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t('close')))+" ")])]})],2):_vm._e(),(_vm.bottomRibbon)?_c('div',{staticClass:"modal-bottom-ribbon"}):_vm._e()])])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }