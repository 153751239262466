<template>
  <ctk-dialog
    v-model="dialogValue"
    :modal-class="`ctk-aside-dialog ${$attrs['modal-class']}`"
    :max-width="onlyAside ? '500px' : '875px'"
    hide-header
    hide-footer
    persistent
  >
    <slot name="body">
      <ctk-dialog-close-button
        class="ctk-aside-dialog__close tw-absolute tw-top-0 tw-right-0"
        data-test="close"
        @click.native="close"
      />

      <div class="tw-flex tw-flex-col md:tw-flex-row ctk-aside-dialog__body">
        <div
          :class="{
            'ctk-aside-dialog__aside--illustration': hasIllustration,
            'ctk-aside-dialog__aside': !onlyAside,
            'ctk-aside-dialog__onlyAside' : onlyAside
          }"
          class="tw-relative tw-w-full tw-flex tw-flex-col tw-p-4 md:tw-p-8 tw-overflow-y-auto"
          data-test="aside"
        >
          <h2
            class="ctk-aside-dialog__aside__title tw-relative tw-font-medium tw-text-2xl tw-leading-none tw-pr-6 md:tw-pr-0"
            data-test="title"
            v-text="title"
          />

          <div
            :class="{
              'ctk-aside-dialog__aside__container tw-mb-10': hasIllustration
            }"
          >
            <slot name="aside" />
          </div>
        </div>
        <div
          v-if="!onlyAside"
          class="ctk-aside-dialog__main"
          data-test="main"
        >
          <slot />
        </div>
      </div>
    </slot>
  </ctk-dialog>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import CtkDialog from '@/components/CtkDialog/index.vue'
  import CtkDialogCloseButton from '@/components/CtkDialog/_subs/CtkDialogCloseButton/index.vue'

  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  /**
   * Dialog component to display the "aside" dialog format with the left gray-ish area
   * and the plain white content.
   * @module component - CtkAsideDialog
   * @param {string} title
   * @param {boolean} hasIllustration
   * @emits close
   */
  export default defineComponent({
    name: 'CtkAsideDialog',
    props: {
      title: {
        type: String,
        required: true
      },
      hasIllustration: {
        type: Boolean,
        default: true
      },
      value: {
        type: Boolean,
        default: true
      },
      onlyAside: {
        type: Boolean,
        default: false
      }
    },
    components: {
      CtkDialog,
      CtkDialogCloseButton
    },
    setup (props) {
      const { state: dialogValue } = useModelGetterSetter(props, 'value')

      function close () {
        dialogValue.value = false
      }

      return {
        dialogValue,
        close
      }
    }
  })
</script>

<style lang="scss">

  .ctk-aside-dialog {
    @media only screen and (min-width: $breakpoint-tablet) {
      &__aside {
        width: 45%;
      }

      &__main {
        width: 55%;
      }
    }

    &__onlyAside {
      background-color: $light-gray;
    }

    &__aside {
      background-color: $light-gray;
      border-radius: 0.3rem;

      &__title {
        margin-bottom: 3.5rem;

        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -0.75rem;
          width: 190px;
          height: 1px;
          background-color: $secondary-text;
        }
      }

      &--illustration {
        /**
         * Show the illustration when the height is sufficient to not overlap the
         * explanation & summary
         */
        @media only screen and (min-height: 750px) {
          &::after {
            position: absolute;
            content: '';
            left: 0;
            bottom: 16px;
            height: 110px;
            width: 100%;
            background-image: url('~@/assets/img/illustrations/form-sidebar.svg');
            background-size: cover;
            background-position: center bottom;
            z-index: 0;
          }
        }
      }
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      &__aside {
        &--illustration {
          padding-bottom: 0;
        }

        &::after {
          display: none;
        }
      }
    }
  }

  .ctk-aside-dialog .modal-wrapper {
    justify-content: unset;
    align-items: unset;
  }

  .ctk-aside-dialog .modal-container {
    max-height: initial;
    margin: auto !important;
    width: 100%;
  }

</style>
