<template>
  <div class="uploaded-file-view">
    <ui-ctk-icon
      name="checklist-alt"
      class="tw-text-xl"
    />
    <div class="tw-flex-grow tw-flex tw-justify-start tw-items-center tw-flex-wrap">
      <ui-badge
        color="blue"
        data-test="uploaded-file-view__badge"
      >
        <span
          class="uploaded-file-view__badge"
          v-text="label"
        />
      </ui-badge>
    </div>
    <b-dropdown
      variant="link"
      dropdown
      no-caret
      class="ctk-dropdown tw-flex tw-items-center"
      boundary="window"
      :right="true"
      @click.stop="requestFiles"
    >
      <template #button-content>
        <div class="tw-flex tw-m-auto">
          <ui-material-icon
            name="more_vert"
          />
        </div>
      </template>
      <b-dropdown-item
        v-if="files.view"
        :href="files.view"
        target="_blank"
        class="uploaded-file-view__action"
      >
        {{ $t('app.buttons.view') }}
      </b-dropdown-item>
      <b-dropdown-item
        v-if="files.download"
        :href="files.download"
        target="_blank"
        class="uploaded-file-view__action"
      >
        {{ $t('app.buttons.download') | capitalize }}
      </b-dropdown-item>
      <b-dropdown-divider />
      <b-dropdown-item
        @click="$emit('update')"
        class="uploaded-file-view__action"
        data-test="uploaded-file-view__edit"
      >
        {{ $t('edit') | capitalize }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
  /**
   * @module component - uploadedFileView
   */
  export default {
    name: 'UploadedFileView',
    data () {
      return {
        error: false,
        success: false,
        files: {
          view: null,
          download: null
        }
      }
    },
    props: {
      downloadFileUrl: {
        type: String,
        required: true
      },
      label: {
        type: String,
        required: true
      }
    },
    emits: [
      'update'
    ],
    mounted () {
      this.requestFiles()
    },
    methods: {
      requestFiles () {
        // TODO uncomment once back end for A1/1D is done
        // const downloadFile = axios.get(this.downloadFileUrl, {
        //   params: {
        //     disposition: 'attachment'
        //   }
        // })
        //   .then((response) => {
        //     this.files.download = response.data.url
        //     return response
        //   })
        //
        // const viewFile = axios.get(this.downloadFileUrl)
        //   .then((response) => {
        //     this.files.view = response.data.url
        //     return response
        //   })
        //
        // /**
        //  * Wait for both files to be ready, to open the popover.
        //  */
        // return Promise.all([
        //   viewFile,
        //   downloadFile
        // ])
        //   .then((response) => {
        //     this.success = true
        //     this.popoverVisible = true
        //
        //     this.$emit('success', response)
        //   })
        //   .catch(() => {
        //     this.$emit('failed')
        //   })
        //   .finally(() => {
        //     /**
        //      * Reset the button state to it's default state after some seconds.
        //      */
        //     setTimeout(() => {
        //       this.resetAsDefault()
        //     }, 2000)
        //   })
      },
      resetAsDefault () {
        this.success = false
      }
    },
    watch: {
      downloadFileUrl () {
        this.success = false
        this.requestFiles()
      }
    }
  }
</script>

<style lang="scss" scoped>
.uploaded-file-view {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(214, 214, 218, var(--tw-border-opacity));
  border-left-color: rgba(39, 84, 145, var(--tw-border-opacity));
  border-style: solid;
  border-width: 1px;
  border-left-width: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0;
  -webkit-flex: 1 1 0;
  flex: 1 1 0;
  height: auto;
  font-size: 1rem;
  overflow: hidden;
  padding: 1rem;
  padding-left: 1.25rem;
  grid-gap: 1rem;
  gap: 1rem;
}
@media (min-width: 770px) {
  .uploaded-file-view {
    min-height: 58px;
  }
}
.uploaded-file-view__action {
  --tw-text-opacity: 1;
  color: rgba(117, 116, 116, var(--tw-text-opacity));
}
.uploaded-file-view__action .ui-button {
  width: 100%;
  padding: 0;
  border: none;
  min-height: 0;
  background-color: inherit;
  color: inherit;
}
.uploaded-file-view::v-deep .dropdown-menu {
  border-radius: 0.25rem;
  padding: 0px;
  box-shadow: 2px 2px 3px 0 $gray;
}
.uploaded-file-view::v-deep .dropdown-menu .dropdown-item {
  padding: 0px;
  color: inherit;
  text-align: center;
}
.uploaded-file-view::v-deep .dropdown-menu .dropdown-divider {
  margin: 0px;
}
</style>
