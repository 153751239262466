<template>
  <router-link
    :to="{
      name: routeName,
      params: {
        uuid: shipment.uuid
      }
    }"
    class="tw-flex tw-flex-col shipment-search-item flex-fixed"
  >
    <shipment-item-header
      :shipment="shipment"
      :has-expiration="false"
      :has-infos="false"
    />
    <shipment-item-content
      :shipment="shipment"
      :has-options="false"
      :has-price="false"
      :has-tracking="false"
      :has-cancellation-dates="false"
      :has-rating="false"
    />
  </router-link>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import ShipmentItemHeader from '@/views/Search/ShipmentSearchItem/_subs/ShipmentItemHeader/index.vue'
  import ShipmentItemContent from '@/views/Search/ShipmentSearchItem/_subs/ShipmentItemContent/index.vue'

  /**
   * @module component - ShipmentSearchItem
   * @param {object} shipment
   */
  export default defineComponent({
    name: 'ShipmentSearchItem',
    components: {
      ShipmentItemHeader,
      ShipmentItemContent
    },
    props: {
      shipment: {
        type: Object,
        required: true
      },
      routeName: {
        type: String,
        required: true
      }
    }
  })
</script>

<style lang="scss">

.shipment-search-item {
  position: relative;
  background-color: white;
  border: 1px solid #E9E9EA;
  text-decoration: none !important;
  color: $primary-text;
  transition: box-shadow 200ms ease;

  .shipment-item-header,
  .shipment-item-content {
    transition: background-color 200ms ease;
  }

  &:hover {
    .shipment-item-header {
      background-color: #DADADB;
    }

    .shipment-item-content {
      background-color: #F3F3F3;
    }
  }

  &:focus {
    @include focusShadow($info);

    position: relative;
    outline: none;
  }
}

.shipment-search-item .shipment-item-address-dates {
  flex: 1 !important;
}

</style>
