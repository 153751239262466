<template>
  <div class="required-documents-section tw-px-3 tw-py-2 tw-rounded">
    <div class="required-documents-section__header tw-flex tw-items-end tw-pb-1 tw-mb-3">
      <img
        :src="require('@/assets/img/icons/quotation/contract.svg')"
        alt="icon"
        class="tw-mr-2 tw-select-none"
        width="20"
      >
      <div class="tw-font-bold tw-text-sm tw-leading-tight">
        {{ $t('dialog_booking.required_documents_title') }}
      </div>
    </div>
    <div class="required-documents-section__content tw-m-0">
      {{ $t('dialog_booking.required_documents_paragraphs') }} <a
        @click.stop="$emit('open-proposal-dialog')"
        class="required-documents-section__link"
        data-test="required-documents-section__link"
      >{{ $t('dialog_booking.required_documents_paragraphs_go_to_bid') }}</a>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'RequiredDocumentsSection',
    emits: [
      'open-proposal-dialog'
    ]
  }
</script>

<style lang="scss">

  .required-documents-section {
    &__header {
      border-bottom: 1px solid $info;
    }

    &__link {
      color: $light-blue !important;

      &:hover {
        text-decoration: underline !important;
        cursor: pointer;
      }
    }
  }

  .required-documents-section__content {
    p {
      margin: 0;
    }

    strong {
      font-weight: 500;
    }
  }

</style>
