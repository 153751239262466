



















































































































import UploadedFileView from '@/components/UploadedFileView/UploadedFileView.vue'
import CtkDropzone from '@/components/CtkDropzone/index.vue'
import { computed, defineComponent, reactive, watch } from '@vue/composition-api'
import useStore from '@/composables/useStore'
import Config from '@/services/Config'
import { mapGetters } from 'vuex'
import { env } from '@/../utils/env'

export default defineComponent({
  name: 'RequiredDocumentsStep',
  components: {
    CtkDropzone,
    UploadedFileView
  },
  emits: [
    'back',
    'book',
    'a1-file-uploaded',
    'ad-file-uploaded',
    'update-tos'
  ],
  props: {
    a1FileUuid: {
      type: String
    },
    adFileUuid: {
      type: String
    },
    tos: {
      type: Boolean,
      required: true
    }
  },
  setup (props, { emit }) {
    const store = useStore()
    const getCurrentOffer = computed(() => store.value.getters['offers/getCurrentOffer'])

    const formData = reactive({
      tos: props.tos
    })

    /**
     * @type {import('@vue/composition-api').Ref<number|null>} price
     */
    const price = computed(() => {
      const offer = getCurrentOffer.value

      return offer && offer.pricing
        ? offer.pricing.price
        : null
    })

    function getDropZoneUrl (documentType: string) {
      return `${Config.get(`api.base.${env}`)}v2/companies/${this.getCid}/required-document/${documentType}`
    }

    function onA1Upload (response) {
      if (response.uuid) {
        this.$emit('a1-file-uploaded', response.uuid)
      }
    }

    function onADUpload (response) {
      if (response.uuid) {
        this.$emit('ad-file-uploaded', response.uuid)
      }
    }

    watch(() => formData.tos, (value) => {
      emit('update-tos', value)
    })

    return {
      getCurrentOffer,
      price,
      getDropZoneUrl,
      onA1Upload,
      onADUpload,
      formData
    }
  },
  computed: {
    ...mapGetters('auth', [
      'getCid'
    ]),
    cannotBook () {
      return !this.a1FileUuid || !this.adFileUuid || !this.$props.tos
    }
  },
  methods: {
    showFileUploadError (err) {
      if (err && err.error && err.error.code === 403) {
        this.showError(this.$t('not_allowed_error'))
        return
      }

      if (err && err.error) {
        this.showError(err.error.title || err.error.message)
        return
      }

      if (err) {
        this.showError(this.$t('an_error_has_occurred'))
      }
    },
    showError (message) {
      this.$toasted.error(message, {
        duration: 10000,
        position: 'bottom-right',
        action: {
          text: this.$t('close'),
          onClick: (e, toast) => {
            toast.goAway(0)
          }
        }
      })
    }
  }
})
