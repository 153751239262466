import { render, staticRenderFns } from "./UploadedFileView.vue?vue&type=template&id=576d8e63&scoped=true"
import script from "./UploadedFileView.vue?vue&type=script&lang=js"
export * from "./UploadedFileView.vue?vue&type=script&lang=js"
import style0 from "./UploadedFileView.vue?vue&type=style&index=0&id=576d8e63&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "576d8e63",
  null
  
)

export default component.exports