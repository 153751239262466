<template>
  <ctk-item-tracking
    :active-step="step"
    :label="$t(trackingLabel)"
    class="shipment-item-tracking"
  >
    <ctk-item-tracking-dot
      :active="!hasPickupCompleted"
      :completed="hasPickupCompleted"
      data-test="pickup"
    />
    <ctk-item-tracking-divider
      :completed="hasPickupCompleted"
      data-test="pickup-to-delivery"
    />
    <ctk-item-tracking-dot
      :active="hasPickupCompleted"
      :completed="hasDeliveryCompleted"
      data-test="delivery"
    />
    <ctk-item-tracking-divider
      :completed="hasDeliveryCompleted"
      data-test="delivery-to-pod"
    />
    <ctk-item-tracking-dot
      :active="hasDeliveryCompleted"
      :completed="hasPOD"
      data-test="pod"
    />
  </ctk-item-tracking>
</template>

<script>
  import CtkItemTracking from '@/components/CtkItem/CtkItemTracking/index.vue'
  import CtkItemTrackingDot from '@/components/CtkItem/CtkItemTracking/_subs/CtkItemTrackingDot/index.vue'
  import CtkItemTrackingDivider from '@/components/CtkItem/CtkItemTracking/_subs/CtkItemTrackingDivider/index.vue'

  /**
   * @module component - shipmentItemTracking
   * @param {object} shipment
   */
  export default {
    name: 'ShipmentItemTracking',
    components: {
      CtkItemTracking,
      CtkItemTrackingDot,
      CtkItemTrackingDivider
    },
    props: {
      shipment: {
        type: Object,
        required: true
      }
    },
    computed: {
      /**
       * Returns the current step according to the shipment states
       * @function step
       * @returns {number}
       */
      step () {
        if (this.hasDeliveryCompleted) return 2
        if (this.hasPickupCompleted) return 1
        return 0
      },
      /**
       * Returns the appropriate label according to the shipment states
       * @function trackingLabel
       * @returns {string} label
       */
      trackingLabel () {
        return !this.hasPickupCompleted
          ? 'shipments.labels.to_pickup'
          : this.hasDeliveryCompleted
            ? this.hasPOD
              ? 'shipments.labels.pod_available'
              : 'shipments.labels.pod_pending'
            : 'shipments.labels.to_deliver'
      },
      hasPickupCompleted () {
        return this.shipment.pickup.state === 'completed'
      },
      hasDeliveryCompleted () {
        return this.shipment.delivery.state === 'completed'
      },
      /**
       * Returns true if the shipment has a proof of delivery.
       * @function hasPOD
       * @returns {boolean}
       */
      hasPOD () {
        return this.shipment.proof_of_delivery.state === 'available'
      }
    }
  }
</script>
